/* eslint-disable import/prefer-default-export, react/prop-types */
import * as React from 'react'
import OrdersContextProvider from './src/context/OrderContextProvider'

const wrapRootElement = ({ element }) => (
  <OrdersContextProvider>
    {element}
  </OrdersContextProvider>
)

export {
  wrapRootElement,
}