

const DEFAULT_CONFIG = {
  exponential: true,
  factor: 2,
  interval: 0,
  retries: 2,
}

/**
 *
 *
 * @param {function} fn - promise based function that needs to be executed
 * @param {Array} [args=[]] - arguments that needs to passed to that function
 * @param {Object} [config={}] - configuration for the function
 * @property {Number} config.retries - the maximum number of retries it will do
 * @property {Number} config.interval - the interval in ms between each retry
 * @property {Boolean} config.exponential - to use exponential retry
 * @property {Number} config.factor - interval increment factor
 * @returns {Object} - response
 */
const retryRequest = async (fn, args = [], config = {}) => {
  const defaultConfig = {
    ...DEFAULT_CONFIG,
    ...config,
  }
  const { retries, exponential, factor } = defaultConfig
  let { interval } = defaultConfig

  for (let attempt = 0; attempt <= retries; ++attempt) {
    try {
      /* eslint-disable no-await-in-loop */
      const response = await fn(...args)
      return response
    } catch (error) {
      const errorStatus = error?.response?.status
      if (retries === attempt || errorStatus >= 500 || [400, 401, 404, 423].includes(errorStatus))
        throw error

      interval = exponential ? interval * factor : interval

      /* eslint-disable no-await-in-loop */
      /* eslint-disable no-loop-func */
      if (interval) await new Promise((r) => setTimeout(r, interval))
    }
  }
  return {}
}

export default retryRequest
