import httpRequest from './http-request'

async function execute(restMap, key, method, params, data = null, setHeaders = true) {
  params = params || {}
  let { endpoint } = restMap[key]
  if (params.append) {
    endpoint += params.append
    delete params.append
  }

  if (setHeaders) {
    await this.setHeaders(restMap[key])
  }
  // const withCredentials = [true, false].includes(restMap[key].withCredentials) ?  restMap[key].withCredentials : true
  const retry = restMap[key].retry !== false

  const request = {
    data,
    headers: restMap[key].headers,
    method,
    url: endpoint,
    params,
    // withCredentials,
  }
  return httpRequest(request, restMap[key].model, restMap[key].error, retry)
}

export default execute