import RestApiService from "@littlestores/common/services/RestApiService";
import urls from '../constants/urls'

const dataMap = {
  shopDetails: {
    endpoint: urls.SHOP_DETAILS,
    bodyAsForm: false,
    model: (model) => model.data.data,
  },
}

class ShopDetailsService extends RestApiService {
  constructor() {
    super(dataMap)
  }

  shopDetails = () => this.get('shopDetails')
}

export default ShopDetailsService
