import RestApiService from "@littlestores/common/services/RestApiService";
import ProductModel from '../models/ProductModel'
import urls from '../constants/urls'

const dataMap = {
  products: {
    endpoint: urls.PRODUCTS,
    bodyAsForm: false,
    model: (model) => ProductModel.formJSON(model),
  },
}

class ProductService extends RestApiService {
  constructor() {
    super(dataMap)
  }

  products = (params) => this.get('products',params)
}

export default ProductService
