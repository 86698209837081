import axios from 'axios'
import retryRequest from '@littlestores/common/libs/retry-request'
import Alert from 'react-s-alert'
/**
 * @param {XMLHttpRequest} request
 * @param {Object} model
 * @param {function} onErrorCallback
 * @param {Boolean} retry
 * @returns {Promise<any>}
 */
const httpRequest = async (request, model, onErrorCallback, retry = true) => {
  if (!model) {
    throw new Error('A model object is required.')
  } else {
    return new Promise((resolve, reject) => {
      let configObj = { interval: 1000 }
      if (!retry) {
        configObj = { ...configObj, retries: 0 }
      }

      retryRequest(axios, [request], configObj)
        .then((response) => {
          resolve(model(response))
        })
        /**
         * Responds with an axios error object, e.g.:
         * {boolean} error.isAxiosError
         * {XMLHttpRequest} error.request
         * {Object} error.response
         * {string} message
         */
        .catch((error) => {
          const errorStatus = error?.response?.status
          Alert.error(error?.response?.data?.message+'-'+errorStatus || error.toString())
          if (
            errorStatus >= 400 &&
            // Add logging for debugging in non prod
            process.env.FR_RELEASE_LEVEL !== 'prod'
          ) {
            console.error('RestApiServiceError', error)
          }

          if (errorStatus >= 500) {
            // reporter.trackError(error)
          }
          if (onErrorCallback) {
            onErrorCallback(error) // let the error function handle it
          } else {
            reject(error) // throw the error back up for the caller to handle.
          }
        })
    })
  }
}

export default httpRequest
