import { createContext } from 'react'

const OrdersContext = createContext({
  shopName: '',
  address: '',
  orders: [],
  stock: [],
  categories: [],
  clearOrders() {},
  updateOrders() {},
  getUserName() {},
  getUserPhoneNumber() {},
  getSavedOrders() {},
  setUserName() {},
  setUserPhoneNumber() {},
  saveOrders() {},
  setNextCurrentPage() {},
  getSearchedItems() {},
  setFilters(){},
  getUserAddress(){},
  setUserAddress(){}
})

export default OrdersContext
