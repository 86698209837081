class ProductModel {
  static formJSON(data) {
    const productArray = data.data.data.products

    return {
      stock: productArray.map(_data => ({
        id: _data.id,
        title: _data.name,
        price: _data.price,
        description: _data.details.description || '',
        quantity: _data.details.size || '',
        outOfStock: _data.status !== 'available',
        imgUrl: _data.assets && _data.assets[0] || ''
      })),
      nextStock: data.data.data.pagination
    }
  }
}

export default ProductModel
