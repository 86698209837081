import execute from '@littlestores/common/libs/service-helpers/execute'

class RestApiService {
  constructor(restMap) {
    this.restMap = restMap
  }

  /**
   * Perform a get request
   * @param {string} key - the key in the service map to use
   * @param {object} [params] - optional rest params
   * @param {object} [data] - the data to use in the request body
   */
  get(key, params, data) {
    return this.execute(this.restMap, key, 'GET', params, data, false)
  }

  /**
   * Perform a post request
   * @param {string} key - the key in the service map to use
   * @param {object} [params] - optional rest params
   * @param {object} [data] - the data to use in the request body
   */
  post(key, params, data) {
    return this.execute(this.restMap, key, 'POST', params, data, false)
  }

  /**
   * Perform a put request
   * @param {string} key - the key in the service map to use
   * @param {object} [params] - optional rest params
   * @param {object} [data] - the data to use in the request body
   */
  put(key, params, data) {
    return this.execute(this.restMap, key, 'PUT', params, data, false)
  }

  /**
   * Perform a patch request
   * @param {string} key - the key in the service map to use
   * @param {object} [params] - optional rest params
   * @param {object} [data] - the data to use in the request body
   */
  patch(key, params, data) {
    return this.execute(this.restMap, key, 'PATCH', params, data, false)
  }

  /**
   * Perform a delete request
   * @param {string} key - the key in the service map to use
   * @param {object} [params] - optional rest params
   * @param {object} [data] - the data to use in the request body
   */
  delete(key, params, data) {
    return this.execute(this.restMap, key, 'DELETE', params, data, false)
  }

  execute(...args) {
    return execute.call(this, ...args)
  }
}

export default RestApiService