
const isBrowser = typeof window !== `undefined`
let BASE = ''
if(isBrowser){
  BASE = window?.location?.origin
  if(BASE.includes('localhost'))
    BASE =  'https://prasoons-tech-store.littlestores.in'
}



export default {
  GET_ORDERS: `${BASE}/api/order`,
  PRODUCTS: `${BASE}/api/products/`,
  ORDER: `${BASE}/api/order/`,
  SHOP_DETAILS: `${BASE}/api/store-details`,
  PRODUCT_CATEGORIES: `${BASE}/api/product-categories`,
  COUNTRY_DETAILS: `https://api.littlestores.in/country-details`
}