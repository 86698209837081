import PropTypes from 'prop-types'
import React, {Component} from 'react'
import ProductService from '../services/ProductService'
import ShopDetailsService from '../services/ShopDetailsService'
import OrdersContext from './OrdersContext'
import CategoriesService from "../services/CategoriesService";
import DialingCodeService from "../services/DialingCodeService";


const productService = new ProductService()
const shopDetailsService = new ShopDetailsService()
const categoriesService = new CategoriesService()
const dialingCodeService = new DialingCodeService()

export default class OrdersContextProvider extends Component {
    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    }

    constructor(props) {
        super(props)
        this.state = {
            orders: this.getSavedOrders(),
            nextStock: null,
            stock: [],
            shopName: '',
            address: '',
            storeCategory: '',
            deliveryOption: '',
            storeDetails: {},
            currentPage: 1,
            categories: [],
            dialingCodes: [],
            currentFilter: '',
            error : false
        }
    }

    componentDidMount() {
        shopDetailsService
            .shopDetails()
            .then(data => {
                this.setState({
                    storeDetails: data,
                    shopName: data.name,
                    address: data.address,
                    deliveryOption: data.delivery_option,
                    storeCategory: data.category_name
                })
            })


        this.getProductService()
        this.getCategoriesService()
        this.getDialingCode()

    }

    getDialingCode = () => {
        dialingCodeService
            .dialingCodeDetails()
            .then(data => {
                this.setState({dialingCodes: [...data]})
            })
    }
    getProductService = (options, append = true) => {
        productService
            .products(options)
            .then(data => {
                if (append) {
                    this.setState((prevState) => ({
                        stock: [...prevState.stock, ...data.stock],
                        nextStock: data.nextStock
                    }))
                } else {
                    this.setState((prevState) => ({
                        stock: [...data.stock],
                        nextStock: data.nextStock
                    }))
                }

            })
            .catch( err => this.setState({error:true}))
    }

    getCategoriesService = () => {
        categoriesService
            .productCategories()
            .then(data => {
                this.setState({categories: [...data]})
            })
    }

    getContextValues = () => {
        const orders = Object.values(this.state.orders)
        return {
            ...this.state,
            orders,
            clearOrders: this.clearOrders,
            updateOrders: this.updateOrders,
            getUserName: this.getUserName,
            getUserPhoneNumber: this.getUserPhoneNumber,
            getSavedOrders: this.getSavedOrders,
            setUserName: this.setUserName,
            setUserPhoneNumber: this.setUserPhoneNumber,
            saveOrders: this.saveOrders,
            setNextCurrentPage: this.setNextCurrentPage,
            getSearchedItems: this.getSearchedItems,
            setFilters: this.setFilters,
            getUserAddress: this.getUserAddress,
            setUserAddress: this.setUserAddress,
        }
    }


    setNextCurrentPage = () => {
        let currentPage = this.state.nextStock.current_page
        const totalPage = this.state.nextStock.total_pages
        if (currentPage + 1 <= totalPage) {
            currentPage += 1
            this.getProductService({page: currentPage})

        }

    }


    setFilters = (filterId) => {
        this.setState({stock: [], nextStock: null, currentFilter: filterId})
        this.getProductService({categories: filterId}, false)
    }

    getSearchedItems = (query) => {
        if (query.length > 0)
            this.getProductService({search: query}, false)

        else
            this.getProductService({categories: this.state.currentFilter, search: query}, false)
    }


    getUserName() {
        return JSON.parse(localStorage.getItem('littlestores.username')) || ''
    }

    setUserName = (userName) => localStorage.setItem('littlestores.username', JSON.stringify(userName, null, 2))

    getUserPhoneNumber() {
        return JSON.parse(localStorage.getItem('littlestores.phonenumber')) || ''
    }

    getUserAddress() {
        return JSON.parse(localStorage.getItem('littlestores.address')) || ''
    }

    setUserAddress = (userAddress) => localStorage.setItem('littlestores.address', JSON.stringify(userAddress, null, 2))

    setUserPhoneNumber = (userPhoneNumber) => localStorage.setItem('littlestores.phonenumber', JSON.stringify(userPhoneNumber, null, 2))

    updateOrders = ({id, units, ...props}) => {
        const order = {...this.state.orders[id], ...props}
        order.id = id
        order.units = units

        this.setState((prevState) =>
                ({...prevState, orders: {...prevState.orders, [id]: {...order}}}),
            this.saveOrders
        )
    }

    clearOrders = () => {
        this.setState(() => ({orders: {}}), this.saveOrders)
    }

    getSavedOrders = () => {
        return JSON.parse(localStorage.getItem(`${window.location.hostname}.orders`)) || {}
    }

    saveOrders = () => {
        localStorage.setItem(`${window.location.hostname}.orders`, JSON.stringify(this.state.orders, null, 2))
    }

    render() {
        return (
            <OrdersContext.Provider value={this.getContextValues()}>
                {this.props.children}
            </OrdersContext.Provider>
        )
    }
}
