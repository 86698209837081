import urls from "../constants/urls";
import RestApiService from "@littlestores/common/services/RestApiService";

const dataMap = {
    dialingCodeDetails: {
        endpoint: urls.COUNTRY_DETAILS,
        bodyAsForm: false,
        model: (model) => model.data.data,
    },
}
class DialingCodeService extends RestApiService{
    constructor() {
        super(dataMap)
    }

    dialingCodeDetails = () => this.get('dialingCodeDetails')
}

export default DialingCodeService
