import urls from "../constants/urls";
import RestApiService from "@littlestores/common/services/RestApiService";

const dataMap = {
    productCategories: {
        endpoint: urls.PRODUCT_CATEGORIES,
        bodyAsForm: false,
        model: (model) => model.data.data,
    },
}
class CategoriesService extends RestApiService{
    constructor() {
        super(dataMap)
    }

    productCategories = () => this.get('productCategories')
}

export default CategoriesService
